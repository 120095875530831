@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
body{
  background-color: #fff;
  font-family: 'Assistant', sans-serif;
}
header{
height: 13vh;
}
main{
  margin-top: 13vh;
}
.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0e4769;
  opacity: 0.5; /* Adjust this value to control the intensity of the color overlay. */
  pointer-events: none; /* This ensures that the overlay doesn't interfere with any interactions on the image. */
  z-index: 1; /* This ensures the overlay is above the image but below the content. */
}
li {
  position: relative;
}
.textAmz{
  color: #0e4769;
}
.niceBg{
  background-color: #fff;
}
#containerOfImage1{
        margin-right: 10rem;
}
.contactBg{
  background: linear-gradient(130deg, #D9D9D9 0%, #074165 100%);
}

.scale-up-center {
	-webkit-animation: scale-up-center 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
.headerText{
  font-size: 1rem;
  line-height: 2;
}
/* styles.css */
@media (min-width: 640px) {
  .headerText{
    font-size: 3.75rem;
    line-height: 2;
  }
}
@media (min-width: 1500px) {
  #blockquote{
    opacity: 0;
  }
}
.liberty {
  font-size: 3.75rem;
  line-height: 1.5;
 }
@media (max-width: 768px) { 
  .liberty {
    font-size: 2rem;
    line-height: 1.3;
   }
}  

.custom-list {
  list-style-image: url('https://ik.imagekit.io/kv2uqda6y/Vector%20(2).svg?updatedAt=1689844816986');
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.typewriter {
  overflow: hidden;
  white-space: nowrap;
  animation: typewriter 3s steps(40) 1s 1 normal both;
}
.main{
  display: block;
}
#second{
  display: none;
}
.bgHolder{
  height: 54vh;
  background-image: url('https://ik.imagekit.io/kv2uqda6y/pexels-fauxels-3184325.jpg?updatedAt=1690279372226');
}
.textHolder{
  right : 30vw;
}
@media (max-width: 1480px){
  .textHolder{
    right : 0;
  }
}
@media (max-width: 746px) {
  .main{
    display: none;
  }
  .bgHolder{
    height: 43vh;
  }
  #second{
    display: block;
  }
  
  .buckets{
    width: 90vw;
    height: 27vh;
  }
  #XecutiveTeam{
    width: 100vw;
  }
  .two-line-text{
    display: none;
  }
  
  
}
/* ----------------------------------------------
 * Generated by Animista on 2023-7-16 1:1:28
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-center {
        0% {
          -webkit-transform: scale(0.5);
                  transform: scale(0.5);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      @keyframes scale-up-center {
        0% {
          -webkit-transform: scale(0.5);
                  transform: scale(0.5);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      @keyframes typing {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
      li {
        display: block;
        
      }
      
   /* ----------------------------------------------
 * Generated by Animista on 2023-7-31 17:24:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
 .fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2023-8-4 15:39:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
 
 .tracking-in-contract-bck-bottom {
	-webkit-animation: tracking-in-contract-bck-bottom 5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-contract-bck-bottom 5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}
/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
/* ----------------------------------------------
 * Generated by Animista on 2023-8-7 18:6:3
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-contract-bck-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes tracking-in-contract-bck-bottom {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(300px);
            transform: translateZ(400px) translateY(300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck-bottom {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(300px);
            transform: translateZ(400px) translateY(300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2023-8-9 9:4:52
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

 .scale-in-center {
	-webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 /**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-8-9 12:44:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
 .tracking-in-contract-bck-bottom {
	-webkit-animation: tracking-in-contract-bck-bottom 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-contract-bck-bottom 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}
/**
 * ----------------------------------------
 * animation tracking-in-contract-bck-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes tracking-in-contract-bck-bottom {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(300px);
            transform: translateZ(400px) translateY(300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck-bottom {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(300px);
            transform: translateZ(400px) translateY(300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
